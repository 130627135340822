<template>
  <div>
    <v-container fluid class="pa-6 ">
      <DatatableV2 :displayTitle="false" :flat="false" :url="apiUrl"/>
    </v-container>
  </div>
</template>

<script>
import DatatableV2 from '@/components/DatatableV2';

export default {
  name: "MissionDetailContactList",
  components: {
    DatatableV2,
  },
  data() {
    return {
    };
  },
  computed: {
    apiUrl(){
      return "/mission/contactList?id=" + this.$route.params.mission_id;
    },
  },
  methods: {},
};
</script>
